html {
    scroll-behavior: smooth;
}

body {
    height: 100vh;
}

.App {
    position: relative;
    overflow-x: hidden;
}

.navContainer {
    position: fixed;
    width: 100vw;
    top: 0;
}

.positionAbsolute {
    position: absolute;
}

.positionFixed {
    position: fixed;
    z-index: 300;
}

.positionRelative {
    position: relative;
}

.carouselArrows {
    transition: all 0.1s ease-in-out;
    transform: scale(1.7);
}

.carouselArrows:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(2);
}

.triangleDivTop {
    position: relative;
    width: 0;
    height: 0;
    border-bottom: 80px solid white;
    border-left: 100vw solid transparent;
    background-color: transparent;
    z-index: 100;
    margin-top: -78px;
    margin-bottom: 45px;
}

.triangleDivBottom {
    position: relative;
    width: 0;
    height: 0;
    border-top: 80px solid white;
    border-right: 100vw solid transparent;
    background-color: transparent;
    z-index: 100;
    margin-bottom: -78px;
}

.navLogo {
    cursor: pointer;
}


.navLink {
    text-decoration: none;
    transition: all 0.1s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
}

.navActive {
    opacity: 1;
}

.navLink:hover {
    transition: all 0.1s ease-in-out;
    opacity: 1;
}

.projectOverlay div:first-of-type {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    height: 0;
}

.projectOverlay:hover > div:first-of-type {
    transition: all 0.2s ease-in-out;
    opacity: 1;
    height: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.projectOverlay {
    transition: all 0.2s ease-in-out;
}

.projectOverlay:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.02);
}

p {
    line-height: 2;
    font-size: 18px;
}

/* Snowflakes */
/* customizable snowflake styling */
.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 2px rgba(0,0,0,0.4);
    pointer-events: none;
}

@-webkit-keyframes snowflakes-fall {
    0% {
        top: -10%
    }
    100% {
        top: 100%
    }
}

@-webkit-keyframes snowflakes-shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }
}

@keyframes snowflakes-fall {
    0% {
        top: -10%
    }
    100% {
        top: 100%
    }
}

@keyframes snowflakes-shake {
    0%, 100% {
        transform: translateX(0)
    }
    50% {
        transform: translateX(80px)
    }
}

.snowflake {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall, snowflakes-shake;
    -webkit-animation-duration: 10s, 3s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count: infinite, infinite;
    -webkit-animation-play-state: running, running;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 10s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
    left: 1%;
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
    left: 10%;
    -webkit-animation-delay: 1s, 1s;
    animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
    left: 20%;
    -webkit-animation-delay: 6s, .5s;
    animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
    left: 30%;
    -webkit-animation-delay: 4s, 2s;
    animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
    left: 40%;
    -webkit-animation-delay: 2s, 2s;
    animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
    left: 50%;
    -webkit-animation-delay: 8s, 3s;
    animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
    left: 60%;
    -webkit-animation-delay: 6s, 2s;
    animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
    left: 70%;
    -webkit-animation-delay: 2.5s, 1s;
    animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
    left: 80%;
    -webkit-animation-delay: 1s, 0s;
    animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
    left: 90%;
    -webkit-animation-delay: 3s, 1.5s;
    animation-delay: 3s, 1.5s
}

.snowflake:nth-of-type(10) {
    left: 25%;
    -webkit-animation-delay: 2s, 0s;
    animation-delay: 2s, 0s
}

.snowflake:nth-of-type(11) {
    left: 65%;
    -webkit-animation-delay: 4s, 2.5s;
    animation-delay: 4s, 2.5s
}


/* Loading animation */

.animated-icon{
    fill: rgb(130,200,130);
    position: absolute;
    transform: scale(0) rotate(-45deg);
    transform-origin: bottom center;
    animation: scaleAndRotate 6s infinite;
}

.pencil {
    animation-delay: 0s;
}

.seedling {
    animation-delay: 2s;
}

.hammer {
    animation-delay: 4s;
}

.opacity0 {
    transition: opacity 1.5s ease-out;
    opacity: 0;
    pointer-events: none;
}

@keyframes scaleAndRotate {
    0% {
        translate: scale(0) rotate(-45deg);
    }
    10% {
        transform: scale(1) rotate(0deg);
    }
    60% {
        transform: scale(0) rotate(45deg);
    }
}